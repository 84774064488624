import { useEffect, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { dotask } from "@/api";
import Nav from "@/components/Culture/Nav";
import Partners from "@/components/Culture/Partners";
import PEPES from "@/components/Culture/PEPE";
import { useUserData } from "@/contexts/SubmitRequest";

const Culture = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentStatus, setCurrentStatus] = useState(-1);
  const [currentTask, setCurrentTask] = useState(-1);
  const { handleGetSuccessTask, completeList } = useUserData();

  const handleDoTask = async (scoreType: number) => {
    setCurrentTask(scoreType);
    setCurrentStatus(3);
    const res = await dotask(scoreType);
    if (res.code === 200) {
      const data = res.data;
      setTimeout(() => {
        setCurrentStatus(1);
        handleGetSuccessTask();
      }, 2000);
    } else {
      setCurrentStatus(2);
    }
  };

  useEffect(() => {
    handleGetSuccessTask();
  }, []);

  return (
    <Flex
      sx={{
        height: "100%",
        width: "100%",
        padding: "30px 0 20px",
        overflowY: "auto",
        color: "#fff",
      }}
      align={"center"}
      flexDir={"column"}
    >
      <Text
        sx={{
          fontSize: "30px",
          fontStyle: "normal",
          fontWeight: 900,
        }}
      >
        For the PEPES culture{" "}
      </Text>
      <Nav
        active={activeIndex}
        onChangeActive={(activeIndex: number) => {
          setActiveIndex(activeIndex);
        }}
      ></Nav>

      <Box
        sx={{
          padding: "0 24px",
          width: "100%",
        }}
      >
        {activeIndex === 0 && (
          <Box
            sx={{
              width: "100%",
            }}
          >
            <PEPES
              currentTask={currentTask}
              currentStatus={currentStatus}
              completeList={completeList}
              handleDoTask={handleDoTask}
            ></PEPES>
          </Box>
        )}

        {activeIndex === 1 && (
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Partners
              currentTask={currentTask}
              currentStatus={currentStatus}
              completeList={completeList}
              handleDoTask={handleDoTask}
            ></Partners>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default Culture;
