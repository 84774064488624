import { Box, Flex, Image, Text } from "@chakra-ui/react";
import TonIcon from "./assets/tonicon.png";
import { ReactComponent as PLIcon } from "./assets/pl.svg";
import { ReactComponent as PLLIcon } from "./assets/pll.svg";
import { ReactComponent as PRIcon } from "./assets/pr.svg";
import { ReactComponent as PRRIcon } from "./assets/prr.svg";

const Page = () => {
  return (
    <Flex
      sx={{
        marginTop: "24px",
        "&> .bt": {
          borderRadius: "10px",
          border: "1px solid  #4C4C4C",
          background: "#232523",
          width: "31px",
          height: "28px",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
      align={"center"}
      justify={"center"}
      gap={"8px"}
    >
      <Flex className="bt">
        <PLLIcon></PLLIcon>
      </Flex>
      <Flex className="bt">
        <PLIcon></PLIcon>
      </Flex>
      <Box
        sx={{
          color: "#A1A1A1",
          fontFamily: "Avenir",
          fontSize: "13px",
        }}
      >
        Page 1 of 999
      </Box>
      <Flex className="bt">
        <PRIcon></PRIcon>
      </Flex>
      <Flex className="bt">
        <PRRIcon></PRRIcon>
      </Flex>
    </Flex>
  );
};

const RoundList = () => {
  return (
    <Box>
      <Box
        sx={{
          height: "300px",
          background: "#232523",
          borderRadius: "25px",
          marginTop: "12px",
          padding: "0 10px",
        }}
      >
        <Flex
          sx={{
            height: "42px",
          }}
        >
          <Flex
            sx={{
              width: "100px",
            }}
            align={"center"}
            justify={"center"}
          >
            Round
          </Flex>
          <Flex
            sx={{
              flex: 1,
            }}
            align={"center"}
          >
            Winner
          </Flex>
        </Flex>
        <Box>
          {[1, 2, 3, 4, 5].map((item, index) => {
            return (
              <Flex
                key={index}
                sx={{
                  height: "50px",
                  borderTop: "1px solid #4C4C4C",
                }}
              >
                <Flex
                  sx={{
                    width: "100px",
                    color: "#A1A1A1",
                    fontSize: "15px",
                    fontWeight: 500,
                  }}
                  align={"center"}
                  justify={"center"}
                >
                  {index}
                </Flex>
                <Flex
                  sx={{
                    flex: 1,
                  }}
                  align={"center"}
                >
                  <Image
                    src={TonIcon}
                    sx={{
                      width: "40px",
                      height: "40px",
                      marginRight: "8px",
                    }}
                  ></Image>
                  <Text
                    sx={{
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: 900,
                    }}
                  >
                    12345
                  </Text>
                </Flex>
              </Flex>
            );
          })}
        </Box>
      </Box>
      <Page></Page>
    </Box>
  );
};

export default RoundList;
