import { Box, Flex, Text, Image } from "@chakra-ui/react";
import ClockIcon from "./assets/clock.png";

const Players = () => {
  return (
    <Box
      sx={{
        height: "322px",
        flexShrink: 0,
        borderRadius: "25px",
        background: "#232523",
        padding: "20px",
        overflow: "auto",
        marginTop: "12px",
      }}
    >
      <Text>{4} Players</Text>
      <Flex
        align={"center"}
        flexDir={"column"}
        gap={"3px"}
        sx={{
          marginTop: "8px",
        }}
      >
        {[1, 2, 3, 4].map((player, index) => {
          return (
            <Flex
              key={index}
              sx={{
                height: "60px",
                borderRadius: "45px",
                background: "#000",
                width: "100%",
                padding: "0 36px 0 12px",
                position: "relative",
              }}
              align={"center"}
            >
              <Box
                sx={{
                  background: "red",
                  position: "absolute",
                  right: "0",
                  width: "30px",
                  height: "100%",
                  borderRadius: "0 45px 45px 0",
                }}
              ></Box>
              <Image
                src={ClockIcon}
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  marginRight: "14px",
                }}
              ></Image>
              <Box
                sx={{
                  fontSize: "15px",
                }}
              >
                <Text>Mert lkk</Text>
                <Text
                  sx={{
                    marginTop: "4px",
                  }}
                >
                  1.5K PEPE
                </Text>
              </Box>
              <Box
                sx={{
                  marginLeft: "auto",
                  fontSize: "15px",
                  textAlign: "right",
                }}
              >
                <Text sx={{}}>52.%</Text>
                <Flex
                  sx={{
                    marginTop: "4px",
                  }}
                >
                  <Image
                    src={ClockIcon}
                    sx={{
                      width: "18px",
                      marginRight: "4px",
                    }}
                  ></Image>
                  <Text>122</Text>
                </Flex>
              </Box>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
};

export default Players;
