import { Box, Text, Image, Flex } from "@chakra-ui/react";
import ArrowIcon from "./assets/arrow.png";
import HistoryNav from "./HistoryNav";
import { useState } from "react";
import ClaimWin from "./ClaimWin";
import RoundList from "./RoundList";

const RoundDetail = ({
  onShowRoundDetails,
}: {
  onShowRoundDetails: () => void;
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [onlyYour, setOnlyYour] = useState(false);

  const handleActiveChange = (index: number) => {
    setActiveIndex(index);
  };
  const handleOnlyYourChange = () => {
    setOnlyYour(!onlyYour);
  };

  return (
    <Box sx={{}}>
      <Box
        sx={{
          background: "#97E181",
          width: "100%",
          borderRadius: "25px",
          overflow: "hidden",
        }}
      >
        <Flex
          sx={{
            height: "48px",
            paddingLeft: "24px",
          }}
          onClick={onShowRoundDetails}
          align={"center"}
        >
          <Image
            src={ArrowIcon}
            sx={{
              width: "15px",
              marginRight: "8px",
            }}
          ></Image>
          <Text
            sx={{
              color: "#000",
              fontSize: "15px",
              fontWeight: 500,
            }}
          >
            Current Round
          </Text>
        </Flex>
        <Box
          sx={{
            background: "#232523",
            height: "253px",
            borderRadius: "25px",
            padding: "24px 14px",
          }}
        >
          <HistoryNav
            active={activeIndex}
            onActiveChange={handleActiveChange}
          ></HistoryNav>
          <ClaimWin
            onlyYour={onlyYour}
            onOnlyYourChange={handleOnlyYourChange}
          ></ClaimWin>
        </Box>
      </Box>
      <RoundList></RoundList>
    </Box>
  );
};

export default RoundDetail;
