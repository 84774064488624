import {
  Box,
  Flex,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import HelpIcon from "./assets/help.png";
import WalletIcon from "./assets/walletIcon.png";
import ReferralDrawer from "./ReferralDrawer";
import HelpDrawer from "./HelpDrawer";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { shortenAddress } from "@/utils/address";
import BlackLogoIcon from "@/assets/black-logo.png";

const Header = () => {
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const rawAddress = useTonAddress(true);
  const {
    isOpen: isHelpOpen,
    onOpen: onHelpOpen,
    onClose: onHelpClose,
  } = useDisclosure();
  const {
    isOpen: isReferralOpen,
    onOpen: onReferralOpen,
    onClose: onReferralClose,
  } = useDisclosure({
    // defaultIsOpen: true,
  });

  return (
    <Flex>
      <Flex
        sx={{
          marginLeft: "auto",
        }}
        gap={"6px"}
      >
        <Flex
          align={"center"}
          justify={"center"}
          sx={{
            width: "63px",
            height: "33px",
            borderRadius: "10px",
            border: "1px solid  #4C4C4C",
          }}
          onClick={() => {
            onHelpOpen();
          }}
        >
          <Image
            src={HelpIcon}
            sx={{
              marginRight: "2px",
              width: "14px",
            }}
          ></Image>
          <Text
            sx={{
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: 500,
            }}
          >
            Help
          </Text>
        </Flex>
        {rawAddress && (
          <Flex
            align={"center"}
            justify={"center"}
            sx={{
              width: "63px",
              height: "33px",
              borderRadius: "10px",
              border: "1px solid  #97E181",
            }}
            onClick={() => {
              onReferralOpen();
            }}
          >
            <Text
              sx={{
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 500,
              }}
            >
              Invite
            </Text>
          </Flex>
        )}
        {rawAddress ? (
          <Popover autoFocus={true} closeOnBlur={true}>
            <PopoverTrigger>
              <Flex
                align={"center"}
                justify={"center"}
                sx={{
                  width: "42px",
                  height: "33px",
                  borderRadius: "10px",
                  border: "1px solid  #97E181",
                }}
              >
                <Image
                  src={WalletIcon}
                  sx={{
                    width: "26px",
                  }}
                ></Image>
              </Flex>
            </PopoverTrigger>
            <PopoverContent
              sx={{
                border: "none",
                background: "#fff",
                color: "#000",
                width: "178px",
                "&:focus-visible": {
                  boxShadow: "none",
                },
              }}
            >
              <PopoverBody>
                <Box>
                  <Flex align={"center"}>
                    <Image
                      src={BlackLogoIcon}
                      sx={{
                        width: "20px",
                        height: "20px",
                        marginRight: "8px",
                      }}
                    ></Image>
                    <Text
                      sx={{
                        fontSize: "10px",
                      }}
                    >
                      {shortenAddress(rawAddress, 6, 4)}
                    </Text>
                  </Flex>
                  <Flex
                    align={"center"}
                    justify={"center"}
                    sx={{
                      width: "151px",
                      height: "27px",
                      borderRadius: "14px",
                      background: "#000",
                      color: "#fff",
                      fontSize: "10px",
                      marginTop: "5px",
                    }}
                    onClick={() => {
                      tonConnectUI.disconnect();
                    }}
                  >
                    Disconnect
                  </Flex>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : (
          <Flex
            sx={{
              width: "137px",
              height: "33px",
              justifyContent: "center",
              alignItems: "center",
              background: "transparent",
              fontSize: "15px",
              color: "#fff",
              borderRadius: "10px",
              border: "1px solid #4C4C4C",
            }}
            onClick={() => {
              tonConnectUI.openModal();
            }}
          >
            Connect Wallet
          </Flex>
        )}
      </Flex>

      <ReferralDrawer
        isOpen={isReferralOpen}
        onClose={onReferralClose}
      ></ReferralDrawer>
      <HelpDrawer isOpen={isHelpOpen} onClose={onHelpClose}></HelpDrawer>
    </Flex>
  );
};
export default Header;
