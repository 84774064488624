import axios from "axios";

console.log(process.env.REACT_APP_ENV, "process.env.REACT_APP_ENV");
export const api = axios.create({
  baseURL:
    process.env.REACT_APP_ENV == "production"
      ? "https://api.tonpepes.xyz/api" //https://api.tonpepes.xyz/api
      : "https://api.test.tonpepes.xyz/api",
});

export const login = async (initData: any) => {
  const response = await api.post("/User/Login", initData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getLeaderboard = async () => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/User/GetUserScoreRank/500/1`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getInvite = async () => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/User/GetUserInviteUser/500/1`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getSuccessTask = async () => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/User/SuccessTask`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getLoginAward1 = async () => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/User/LoginAward1/176`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const doLoginAward = async (unixTime?: number) => {
  const token = sessionStorage.getItem("jwtToken");
  if (unixTime) {
    const response = await api.post(
      `/User/DebugLoginAward/176/${unixTime}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } else {
    const response = await api.post(
      `/User/DoTask/176`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
};

export const dotask = async (scoreType: number) => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.post(
    `/User/DoTask/${scoreType}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const getScoreHistory = async (pageIndex: number) => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/User/GetUserScoreHistory/20/${pageIndex}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
