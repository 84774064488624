import { Box, Image } from "@chakra-ui/react";
import LogoIcon from "@/assets/b-logo.png";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getInvite, getLeaderboard, getSuccessTask } from "@/api";
const UserDataContext = createContext<{
  init: boolean;
  userData: any;
  onUserDataChange: (data: any) => void;
  onFirstChange: () => void;
  handleGetFriend: () => void;
  leaderboardMyData: any;
  leaderboardList: any;
  friendList: any;
  completeList: any;
  friendTotal: number;
  leaderboardTotal: number;
  handleGetLeaderboard: () => void;
  handleGetSuccessTask: () => void;
}>(null);

export const UserDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const leaderboardRef = React.useRef(0);
  const friendRef = React.useRef(0);
  const completeRef = React.useRef(0);
  const [init, setInit] = React.useState(false); // [1
  const [userData, setUserData] = React.useState({});

  const [leaderboardMyData, setLeaderboardMyData] = React.useState({
    userId: 0,
    firstName: "",
    lastName: "",
    score: 0,
    rank: "",
  });
  const [leaderboardTotal, setLeaderboardTotal] = useState(0);
  const [leaderboardList, setLeaderboardList] = React.useState([]);
  const [friendList, setFriendList] = React.useState([]);
  const [friendTotal, setFriendTotal] = useState(0);
  const [completeList, setCompleteList] = React.useState([]);
  const onUserDataChange = (data: any) => {
    setUserData(data);
  };

  const handleGetLeaderboard = async () => {
    const now = new Date().getTime();
    if (now - leaderboardRef.current < 1000) {
      return;
    }
    leaderboardRef.current = now;
    const res = await getLeaderboard();
    if (res.code === 200) {
      setLeaderboardTotal(30000 + 3 * res.data.dataCount);
      let newRankList = [];
      let otherData = res.data.data.slice(1, res.data.data.length);
      let myData = res.data.data[0];
      newRankList = otherData;
      setLeaderboardMyData(myData);
      setLeaderboardList(newRankList);
    }
  };

  const handleGetFriend = async () => {
    const now = new Date().getTime();
    if (now - friendRef.current < 1000) {
      return;
    }
    friendRef.current = now;
    const res = await getInvite();
    if (res.code === 200) {
      setFriendTotal(res.data.dataCount);
      setFriendList(res.data.data);
    }
  };

  const handleGetSuccessTask = async () => {
    const now = new Date().getTime();
    if (now - completeRef.current < 1000) {
      return;
    }
    completeRef.current = now;
    const res = await getSuccessTask();
    if (res.code === 200) {
      const data = res.data;
      setCompleteList(data);
    }
  };

  const onFirstChange = () => {
    handleGetLeaderboard();
    handleGetFriend();
    handleGetSuccessTask();
    setTimeout(() => {
      setInit(true);
    }, 1500);
  };

  return (
    <UserDataContext.Provider
      value={{
        init,
        userData,
        onUserDataChange,
        onFirstChange,
        handleGetFriend,
        leaderboardMyData,
        friendList,
        friendTotal,
        leaderboardList,
        completeList,
        leaderboardTotal,
        handleGetLeaderboard,
        handleGetSuccessTask,
      }}
    >
      <Box
        sx={{
          background: "#000",
          height: "100%",
          width: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          transition: "all 0.3s",
          opacity: init ? 0 : 1,
          zIndex: init ? -1 : 100,
        }}
      >
        <Box
          sx={{
            width: "195px",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Image src={LogoIcon} sx={{}}></Image>
        </Box>
      </Box>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => {
  return useContext(UserDataContext);
};
