import { getGameHistory, getGameIngo } from "@/api/game";
import Header from "@/components/Play/Header";
import Players from "@/components/Play/Players";
import RoundDetail from "@/components/Play/RoundDetail";
import RoundEnter from "@/components/Play/RoundEnter";
import RoundInfo from "@/components/Play/RoundInfo";
import SelectBet from "@/components/Play/SelectBet";
import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export interface GameInfo {
  id: number;
  status: number;
  winnerUser: number;
  winnerBetAmount: string;
  player: number;
  totalRewardScore: number;
  totalBetAmount: string;
  createTime: string;
  betHistorys: any[];
}

const Play = () => {
  const [showRoundDetails, setShowRoundDetails] = useState(false);
  const [showBet, setShowBet] = useState(false);
  const [selectAmountIndex, setSelectAmountIndex] = useState(-1);
  const [currentRound, setCurrentRound] = useState("");
  const [gameInfo, setGameInfo] = useState({
    id: 0,
    status: 0,
    winnerUser: 0,
    winnerBetAmount: "0",
    player: 0,
    totalRewardScore: 0,
    totalBetAmount: "0",
    createTime: "",
    betHistorys: [],
  });

  console.log(gameInfo, "gameInfo");

  const handleShowRoundDetails = () => {
    setShowRoundDetails(!showRoundDetails);
  };

  const handleShowBet = () => {
    setShowBet(!showBet);
  };

  const handleGetRingGameInfo = async () => {
    const res = await getGameIngo(currentRound);
    console.log(res, "res");
  };

  const handleSelectAmount = (index: number) => {
    setSelectAmountIndex(index);
  };

  useEffect(() => {
    handleGetRingGameInfo();
  }, [currentRound]);

  return (
    <Box
      sx={{
        color: "#fff",
        height: "100%",
        overflow: "auto",
        padding: "20px 20px 0",
      }}
    >
      <Header></Header>
      <Box
        sx={{
          marginTop: "20px",
        }}
      >
        {!showRoundDetails && (
          <Box
            sx={{
              height: "100%",
              paddingBottom: "180px",
            }}
          >
            {showBet ? (
              <Box>
                <SelectBet
                  onShowBet={handleShowBet}
                  selectAmountIndex={selectAmountIndex}
                  onSelectAmount={handleSelectAmount}
                ></SelectBet>
              </Box>
            ) : (
              <Box>
                <RoundInfo
                  gameInfo={gameInfo}
                  onShowRoundDetails={handleShowRoundDetails}
                ></RoundInfo>
                <Players></Players>
              </Box>
            )}
            <RoundEnter
              selectAmountIndex={selectAmountIndex}
              gameInfo={gameInfo}
              showBet={showBet}
              onShowBet={handleShowBet}
            ></RoundEnter>
          </Box>
        )}
        {showRoundDetails && (
          <Box
            sx={{
              height: "100%",
            }}
          >
            <RoundDetail
              onShowRoundDetails={handleShowRoundDetails}
            ></RoundDetail>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default Play;
