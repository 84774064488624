import { Box, Flex, Text, Image, background } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { LuckyWheel } from "@lucky-canvas/react";
import { useMemo, useRef, useState } from "react";
import ClockIcon from "./assets/clock.png";
import LeftIcon from "./assets/left.png";
import RightIcon from "./assets/right.png";
import RRightIcon from "./assets/rright.png";
import DArrowIcon from "./assets/d-arrow.png";
import { GameInfo } from "@/pages/Play";

const RoundInfo = ({
  gameInfo,
  onShowRoundDetails,
}: {
  gameInfo: GameInfo;
  onShowRoundDetails: () => void;
}) => {
  const [blocks] = useState([{ padding: "8px", background: "transparent" }]);
  // const [prizes] = useState([
  //   { background: "#e9e8fe", fonts: [{ text: "1" }] },
  //   { background: "#b8c5f2", fonts: [{ text: "2" }] },
  //   { background: "#e9e8fe", fonts: [{ text: "3" }] },
  //   { background: "#b8c5f2", fonts: [{ text: "4" }] },
  //   { background: "#e9e8fe", fonts: [{ text: "5" }] },
  //   { background: "#b8c5f2", fonts: [{ text: "6" }] },
  // ]);

  const myLucky = useRef<any>();

  const prizes = useMemo(() => {
    if (gameInfo.betHistorys.length === 0) {
      return [
        {
          background: "#555",
        },
      ];
    }
  }, [gameInfo]);

  return (
    <Box
      sx={{
        height: "360px",
        flexShrink: 0,
        borderRadius: "25px",
        background: "#232523",
        padding: "20px",
      }}
    >
      <Flex sx={{}} align={"center"} justify={"space-between"}>
        <Text>Round #{1000}</Text>
        <Flex
          sx={{
            "& img": {
              width: "31px",
            },
          }}
          align={"center"}
          gap={"10px"}
        >
          <Image src={ClockIcon} sx={{}} onClick={onShowRoundDetails}></Image>
          <Image src={LeftIcon} sx={{}}></Image>
          <Image src={RightIcon} sx={{}}></Image>
          <Image src={RRightIcon} sx={{}}></Image>
        </Flex>
      </Flex>

      <Box
        sx={{
          height: "260px",
          width: "260px",
          position: "relative",
          margin: "30px auto 0",
          borderRadius: "50%",
          border: "6px solid #D9D9D9",
        }}
      >
        <LuckyWheel
          ref={myLucky}
          width="248px"
          height="248px"
          blocks={blocks}
          prizes={prizes}
          onEnd={(prize: any) => {
            // 抽奖结束会触发end回调
            alert("恭喜你抽到 " + prize.fonts[0].text + " 号奖品");
          }}
        />
        <Image
          src={DArrowIcon}
          sx={{
            width: "26px",
            position: "absolute",
            top: "-6px",
            left: "50%",
            transform: "translate(-50%, 0%)",
            zIndex: 1,
          }}
        ></Image>
        <Box
          sx={{
            background: "#232523",
            width: "150px",
            height: "150px",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "50%",
          }}
        ></Box>
      </Box>

      {/* <Text
        onClick={() => {
           myLucky.current.play();
          setTimeout(() => {
            const index = (Math.random() * 6) >> 0;
            myLucky.current.stop(index);
          }, 2500);
        }}
      >
        开始
      </Text> */}
    </Box>
  );
};

export default RoundInfo;
