import tgConfig from "@/constants/tgConfig";
import TWIcon from "@/assets/tw.svg";
import TGIcon from "@/assets/tg.svg";
import LogoIcon from "./assets/logo.png";

export const pepeList = [
  {
    btnText: "Follow",
    title: "@PEPES_TON",
    amount: 500,
    url: tgConfig.TW_URL,
    changeType: 5,
    type: 1,
    icon: TWIcon,
  },
  {
    btnText: "Go",
    title: "Join community",
    amount: 500,
    url: tgConfig.GROUP_URL,
    changeType: 4,
    type: 0,
    icon: TGIcon,
  },
  {
    btnText: "Check",
    title: "Add PEPES to your name",
    amount: 1500,
    url: "",
    changeType: 6,
    type: 2,
  },
  {
    btnText: "Check",
    title: "Add PEPES to your blo",
    amount: 1500,
    url: "",
    changeType: 7,
    type: 2,
  },
  // {
  //   btnText: "Go",
  //   title: "Boost PEPPES Channel",
  //   amount: 500,
  //   url: "https://t.me/boost/Pepes_vibe",
  //   changeType: 7,
  //   type: 0,
  // },
  // {
  //   btnText: "Go",
  //   title: "https://t.me/Pepes_vibe/38",
  //   amount: 500,
  //   url: "",
  //   changeType: 7,
  //   type: 0,
  // },
  // {
  //   btnText: "Go",
  //   title: "React to this",
  //   amount: 500,
  //   url: "https://t.me/Pepes_vibe/41",
  //   changeType: 7,
  //   type: 0,
  // },
  // {
  //   btnText: "Go",
  //   title: "Check out this post on X",
  //   amount: 500,
  //   url: "https://x.com/TonPepes/status/1841499847515656328",
  //   changeType: 7,
  //   type: 1,
  // },
];

const pepe = [
  {
    bgColor: "#232523",
    title: "PEPES",
    logo: LogoIcon,
    list: pepeList,
  },
];

export default pepe;
