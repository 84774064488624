import { Box, Text, Flex, Image } from "@chakra-ui/react";
import React, { useEffect } from "react";
import OtherAvatarIcon from "@/assets/other-avatar.png";
import { useUserData } from "@/contexts/SubmitRequest";

export const RankItem = ({
  isMe = false,
  detail,
  bgColor = "transparent",
  color = "#fff",
  rank,
  paddingRight = "0px",
  paddingLeft = "0px",
  score,
}: {
  isMe?: boolean;
  detail: any;
  bgColor?: string;
  color?: string;
  rank?: string;
  paddingRight?: string;
  paddingLeft?: string;
  score?: string;
}) => {
  return (
    <Flex
      align={"center"}
      sx={{
        borderRadius: "17px",
        background: bgColor,
        height: "51px",
        padding: "0 16px 0 25px",
        marginBottom: "6px",
        color: isMe ? "#000" : "#fff",
        paddingLeft: paddingLeft,
      }}
      justify={"space-between"}
    >
      <Flex>
        <Image
          src={OtherAvatarIcon}
          sx={{
            marginRight: "16px",
            width: "40px",
            height: "40px",
          }}
        ></Image>
        <Box
          sx={{
            fontSize: "15px",
          }}
        >
          <Text
            sx={{
              color: isMe ? "#000" : "#fff",
              height: "20px",
            }}
          >
            {detail
              ? detail?.firstName ?? "" + " " + detail?.lastName ?? ""
              : "  "}
          </Text>
          {
            <Text
              sx={{
                color: isMe ? "#4c4c4c" : "#A1A1A1",
              }}
            >
              {Number(score).toLocaleString()}{" "}
              <span
                style={{
                  fontSize: "10px",
                }}
              >
                PEPES
              </span>{" "}
            </Text>
          }
        </Box>
      </Flex>
      <Text
        sx={{
          fontWeight: 700,
          fontSize: "15px",
          paddingRight: paddingRight,
          color: color,
        }}
      >
        {rank}
      </Text>
    </Flex>
  );
};

const Leaderboard = () => {
  const {
    handleGetLeaderboard,
    leaderboardTotal,
    leaderboardMyData,
    leaderboardList,
  } = useUserData();

  useEffect(() => {
    handleGetLeaderboard();
  }, []);

  return (
    <Box
      sx={{
        padding: "2.7778vh 30px 20px",
        height: "100%",
        overflowY: "auto",
        color: "#fff",
      }}
    >
      <Text
        sx={{
          fontSize: "25px",
          fontStyle: "normal",
          fontWeight: 900,
          textAlign: "center",
        }}
      >
        PEPES Ranking
      </Text>
      <Box
        sx={{
          marginTop: "3.7037vh",
          paddingBottom: "4px",
        }}
      >
        <RankItem
          isMe={true}
          bgColor="#97E181"
          color="#000"
          rank={
            leaderboardMyData.rank > 999
              ? "#999+"
              : "#" + leaderboardMyData.rank
          }
          paddingRight="20px"
          detail={leaderboardMyData}
          paddingLeft="22px"
          score={leaderboardMyData.score}
        ></RankItem>
      </Box>

      <Box
        sx={{
          fontSize: "17px",
          fontStyle: "normal",
          fontWeight: 900,
          marginTop: "40px",
        }}
      >
        {leaderboardTotal > 0 ? 30000 + 3 * leaderboardTotal : 0} Holders
      </Box>

      <div
        style={{
          marginTop: "20px",
        }}
      >
        {leaderboardList.map((item: any, index: number) => {
          let rank = "#" + String(index + 1);
          let color = "#fff";
          if (index === 0) {
            rank = "🥇" + rank;
            color = "#FCC40B";
          } else if (index === 1) {
            rank = "🥈" + rank;
            color = "#B1B1B1";
          } else if (index === 2) {
            rank = "🥉" + rank;
            color = "#AB5820";
          }
          return (
            <RankItem
              color={color}
              key={index}
              rank={rank}
              detail={item}
              score={item.score}
            ></RankItem>
          );
        })}
      </div>
    </Box>
  );
};

export default Leaderboard;
