import { Box, Flex, Text, Image, useDisclosure } from "@chakra-ui/react";
import ClockIcon from "./assets/clock.png";
import TonIcon from "./assets/tonicon.png";
import { ReactComponent as PRRIcon } from "./assets/prr.svg";
import { useMemo } from "react";
import { GameInfo } from "@/pages/Play";

const EnterNow = ({ onClick }: { onClick: () => void }) => {
  return (
    <Flex
      onClick={onClick}
      align={"center"}
      justify={"center"}
      sx={{
        borderRadius: "10px",
        border: "1px solid #4C4C4C",
        background: "#97E181",
        height: "48px",
        marginTop: "12px",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 900,
        color: "#000",
      }}
    >
      Enter Now
    </Flex>
  );
};

const GrayClose = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Flex
      align={"center"}
      justify={"center"}
      sx={{
        borderRadius: "10px",
        border: "1px solid #4C4C4C",
        background: "#4C4C4C",
        height: "48px",
        marginTop: "12px",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 900,
        color: "#A1A1A1",
      }}
    >
      {children}
    </Flex>
  );
};

const BackToCurrent = ({ onClick }: { onClick: () => void }) => {
  return (
    <Flex
      onClick={onClick}
      align={"center"}
      justify={"center"}
      sx={{
        borderRadius: "10px",
        border: "1px solid #97E181",
        background: "#283228",
        height: "48px",
        marginTop: "12px",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 900,
        color: "#97E181",
      }}
    >
      <Text
        sx={{
          marginRight: "8px",
        }}
      >
        {" "}
        Round Closed{" "}
      </Text>
      <PRRIcon
        style={{
          color: "#97E181",
        }}
      ></PRRIcon>
    </Flex>
  );
};

const RoundEnter = ({
  selectAmountIndex,
  gameInfo,
  showBet,
  onShowBet,
}: {
  selectAmountIndex: number;
  gameInfo: GameInfo;
  showBet: boolean;
  onShowBet: () => void;
}) => {
  const btnText = useMemo(() => {
    if (gameInfo.status == 2 || gameInfo.status == 3 || gameInfo.status == 4) {
      return "Round Closed";
    }

    if (selectAmountIndex == -1) {
      return "Please select the amount";
    }
  }, [gameInfo]);

  const handleEnterNow = () => {
    if (!showBet) {
      onShowBet();
      return;
    }
  };

  return (
    <Box
      sx={{
        background: "#283228",
        padding: "8px 20px",
        position: "absolute",
        bottom: "65px",
        left: "0",
        width: "100%",
        height: "158px",
      }}
    >
      <Flex sx={{}} align={"center"} justify={"space-between"}>
        <Text>Round #{999}</Text>
        <Flex
          align={"center"}
          sx={{
            borderRadius: "10px",
            border: "1px solid #97E181",
            height: "30px",
            width: "88px",
            fontSize: "23px",
            color: "#97E181",
            fontWeight: "bold",
          }}
          justify={"center"}
        >
          00:00
        </Flex>
      </Flex>
      <Flex
        sx={{
          marginTop: "14px",
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Text
            sx={{
              fontSize: "13px",
              color: "#A1A1A1",
            }}
          >
            Prize Pool
          </Text>
          <Flex
            align={"center"}
            sx={{
              marginTop: "4px",
            }}
          >
            <Image
              src={TonIcon}
              sx={{
                width: "18px",
                marginRight: "4px",
              }}
            ></Image>
            <Text
              sx={{
                fontSize: "15px",
              }}
            >
              0.03
            </Text>
          </Flex>
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Text
            sx={{
              fontSize: "13px",
              color: "#A1A1A1",
            }}
          >
            Your Entries
          </Text>
          <Flex
            align={"center"}
            sx={{
              marginTop: "4px",
            }}
          >
            <Image
              src={TonIcon}
              sx={{
                width: "18px",
                marginRight: "4px",
              }}
            ></Image>
            <Text
              sx={{
                fontSize: "15px",
              }}
            >
              0.03
            </Text>
          </Flex>
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Text
            sx={{
              fontSize: "13px",
              color: "#A1A1A1",
            }}
          >
            Your Win Chance
          </Text>
          <Flex
            align={"center"}
            sx={{
              marginTop: "4px",
            }}
          >
            <Image
              src={TonIcon}
              sx={{
                width: "18px",
                marginRight: "4px",
              }}
            ></Image>
            <Text
              sx={{
                fontSize: "15px",
              }}
            >
              0.03
            </Text>
          </Flex>
        </Box>
      </Flex>
      {/* <BackToCurrent onClick={onShowBet}></BackToCurrent> */}
      <GrayClose>Round Closed</GrayClose>
      {<EnterNow onClick={handleEnterNow}></EnterNow>}
    </Box>
  );
};

export default RoundEnter;
