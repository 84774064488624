import MemeTownBg from "./assets/memeBg.png";
import MemeTownLogo from "./assets/memeLogo1.png";
import PacketBg from "./assets/packetBg.png";
import PacketLogo from "./assets/packetLogo.png";

import MetaBg from "./assets/metaBg.png";
import MetaLogo from "./assets/metaLogo.png";

import CatchTonBg from "./assets/catchTonBg.png";
import CatchTonLogo from "./assets/catchTonLogo.png";

import GoatsBg from "./assets/goatsBg.png";
import GoatsLogo from "./assets/goatsLogo.png";

import DejenDogBg from "./assets/dejenDogBg.png";
import DejenDogLogo from "./assets/dejenDogLogo.svg";

import BtokBg from "./assets/btokBg.png";
import BtokLogo from "./assets/btokLogo.png";

import RabbitBg from "./assets/rabbitBg.png";
import RabbitLogo from "./assets/rabbitLogo.png";

import BitbotBg from "./assets/bitbotBg.png";
import BitbotLogo from "./assets/bitbotLogo.png";

import KolobokBg from "./assets/kolobokBg.png";
import KolobokLogo from "./assets/kolobokLogo.png";

export const btokList = [
  {
    btnText: "Join",
    title: "Play BAYCMiner",
    amount: 500,
    url: "https://t.me/BtokMinerBot",
    task: 28,
    changeType: 28,
    des: "",
    type: 0,
  },
  {
    btnText: "Join",
    title: "Join BAYCMiner",
    amount: 500,
    url: "https://t.me/bayc_miner",
    task: 29,
    changeType: 29,
    des: "",
    type: 0,
  },
];

export const memeTownList = [
  {
    btnText: "Go",
    title: "Join Channel",
    amount: 500,
    url: "https://t.me/MemeTownNews",
    changeType: 26,
    des: "",
    type: 0,
  },
  {
    btnText: "Join",
    title: "Join Bot",
    amount: 500,
    url: "https://t.me/MemeTownBot/app?startapp=ref_BiXhWs",
    changeType: 27,
    des: "",
    type: 0,
  },
];

export const packetGiftList = [
  {
    btnText: "Go",
    title: "Earn Money",
    amount: 500,
    url: "https://t.me/packetgift_bot/hongbao?startapp=JisRi1uZhq1P26frXf8u",
    changeType: 24,
    des: "",
    type: 0,
  },
  {
    btnText: "Join",
    title: "Join Channel",
    amount: 500,
    url: "https://t.me/packetgift_group",
    changeType: 25,
    des: "",
    type: 0,
  },
];

export const metaList = [
  {
    btnText: "Go",
    title: "Play Game to Earn",
    amount: 500,
    url: "https://t.me/realityrush_bot/play?startapp=refererID1978888931",
    changeType: 21,
    des: "",
    type: 0,
  },
  {
    btnText: "Join",
    title: "Join TG",
    amount: 500,
    url: "https://t.me/RealityMeta",
    changeType: 22,
    des: "",
    type: 0,
  },
];

export const catchTonList = [
  {
    btnText: "Follow",
    title: "Follow CATCH TON X",
    amount: 500,
    url: "https://x.com/CatchTON_app",
    changeType: 18,
    des: "",
    type: 1,
  },
  {
    btnText: "Join",
    title: "Join CATCH TON",
    amount: 500,
    url: "https://t.me/catchton_channel",
    changeType: 19,
    des: "",
    type: 0,
  },
  {
    btnText: "Go",
    title: "Play CATCH TON",
    amount: 500,
    url: "https://t.me/catchton_bot/run?startapp=7d908008-f345-4b46-8c56-e865c1ba2bf7",
    changeType: 20,
    des: "",
    type: 0,
  },
];

export const potusList = [
  {
    btnText: "Play",
    title: "Play POTUS Click",
    amount: 500,
    url: "https://t.me/potusclick_bot/app?startapp=r1978888931",
    task: 17,
    changeType: 17,
    des: "",
    type: 0,
  },
];

export const goatsList = [
  {
    btnText: "Follow",
    title: "Follow GOATS X",
    amount: 500,
    url: "https://x.com/realGOATS_TG",
    changeType: 14,
    des: "",
    type: 1,
  },
  {
    btnText: "Join",
    title: "Join GOATS Channel",
    amount: 500,
    url: "https://t.me/realgoats_channel",
    changeType: 15,
    des: "",
    type: 0,
  },
  {
    btnText: "Go",
    title: "Get GOATS",
    amount: 500,
    url: "https://t.me/realgoats_bot/run?startapp=cc7d8adf-1e48-4695-8e96-81d330666806",
    changeType: 16,
    des: "",
    type: 0,
  },
];

export const qstartList = [
  {
    btnText: "Follow",
    title: "Follow",
    amount: 500,
    url: "https://x.com/StarProtocol_HQ",
    changeType: 12,
    des: "@StarProtocol_HQ on X",
    type: 1,
  },
  {
    btnText: "Join",
    title: "Join WL Giveaway Event",
    amount: 500,
    url: "https://x.com/TonPepes/status/1817821547752763768",
    changeType: 13,
    des: "(worth 200USDT)",
    type: 1,
  },
];

export const dejenDogList = [
  {
    btnText: "Follow",
    title: "Follow @DejenDogBot on X ",
    amount: 500,
    url: "https://x.com/DejenDogBot",
    changeType: 9,
    type: 1,
  },
  {
    btnText: "Play",
    title: "Play DejenDog",
    amount: 500,
    url: "https://t.me/DejenDogBot?start=PepesTon",
    changeType: 10,
    type: 0,
  },
  {
    btnText: "Join",
    title: "Join DejenDog",
    amount: 500,
    url: "https://t.me/DejenNews",
    changeType: 11,
    type: 0,
  },
];

const rabbitList = [
  {
    btnText: "Follow",
    title: "MoonRabbits on X",
    amount: 500,
    url: "https://x.com/moonrabbits_ton",
    changeType: 30,
    type: 1,
  },
  {
    btnText: "Go",
    title: "Join MoonRabbits",
    amount: 500,
    url: "https://t.me/mrbhouse_bot/moonrabbits?startapp=63405455",
    changeType: 31,
    type: 0,
  },
  {
    btnText: "Go",
    title: "Join MoonRabbits Channel",
    amount: 500,
    url: "https://t.me/moonrabbits",
    changeType: 32,
    type: 0,
  },
];

const bitbotList = [
  {
    btnText: "Go",
    title: "Join Bitbot",
    amount: 500,
    url: "https://t.me/Hello_Bitbot/bitbot?startapp=utm_UEVQRVNkR1ZrWDE=",
    changeType: 33,
    type: 0,
  },
  {
    btnText: "Go",
    title: "Join Bitbot Channel",
    amount: 500,
    url: "https://t.me/BitbotOfficial",
    changeType: 34,
    type: 0,
  },
];

const kolobokList = [
  {
    btnText: "Go",
    title: "Subscribe to KOLOBOK",
    amount: 500,
    url: "https://t.me/crbok",
    changeType: 35,
    type: 0,
  },
  {
    btnText: "Go",
    title: "PLAY KOLOBOK",
    amount: 500,
    url: "https://t.me/bokgame_bot?start=2077877692",
    changeType: 36,
    type: 0,
  },
];

export const partner = [
  {
    bg: RabbitBg,
    title: "MoonRabbit",
    logo: RabbitLogo,
    list: rabbitList,
  },
  {
    bg: BitbotBg,
    title: "Bitbot",
    logo: BitbotLogo,
    list: bitbotList,
  },
  {
    bg: KolobokBg,
    title: "KOLOBOK",
    logo: KolobokLogo,
    list: kolobokList,
  },
  {
    bg: BtokBg,
    title: "Btok",
    logo: BtokLogo,
    list: btokList,
  },
  {
    bg: MemeTownBg,
    title: "MEMETOWN",
    logo: MemeTownLogo,
    list: memeTownList,
  },
  {
    bg: PacketBg,
    title: "Packet Gift",
    logo: PacketLogo,
    list: packetGiftList,
  },
  {
    bg: MetaBg,
    title: "Reality Meta",
    logo: MetaLogo,
    list: metaList,
  },
  {
    bg: CatchTonBg,
    title: "Catch Ton",
    logo: CatchTonLogo,
    list: catchTonList,
  },
  {
    bg: GoatsBg,
    title: "GOATS",
    logo: GoatsLogo,
    list: goatsList,
  },
  {
    bg: DejenDogBg,
    title: "DejenDog",
    logo: DejenDogLogo,
    list: dejenDogList,
  },
];

export default partner;
