const tgConfig = {
  MINI_APP_URL: "t.me/TONPEPES_BOT/PEPES",
  GROUP_URL: "https://t.me/Pepes_vibe",
  TW_URL: "https://x.com/TonPepes",
  endpoint: "https://toncenter.com/api/v2/jsonRPC",
  apiKey: "8e2558f85f2a5046a11346f83171e75cf0f855fbb1c1fec76af9132409c84019",
  network: "mainnet",
  usdt: "EQBynBO23ywHy_CgarY9NK9FTz0yDsG82PtcbSTQgGoXwiuA",
};

const devConfig = {
  MINI_APP_URL: "t.me/TONPEPES_BOT/PEPES",
  GROUP_URL: "https://t.me/Pepes_vibe",
  TW_URL: "https://x.com/TonPepes",
  endpoint: "https://testnet.toncenter.com/api/v2/jsonRPC",
  apiKey: "7dae63ba5fb5c1e4433fa1c3795052a2019c5c24f1422199a329d04d4cc70b20",
  network: "testnet",
  usdt: "EQDkJnJjZoxmn1dfbYTqieIOJtIw0matL3EYpGE5_dEZdJQy",
};

const config: any =
  process.env.REACT_APP_ENV == "production" ? tgConfig : devConfig;

export default config;
