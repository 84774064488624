import { Box, Flex, Image, Text } from "@chakra-ui/react";
import PlayDrawer from "./PlayDrawer";
import ReferralIcon from "./assets/referralicon.png";
import CopyIcon from "./assets/copy.png";
import { useClipboard } from "@chakra-ui/react";

const ReferralDrawer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { hasCopied, onCopy } = useClipboard(
    "https://yologames.io/yolo/blast?code=4896C"
  );
  return (
    <PlayDrawer isOpen={isOpen} onClose={onClose} title="Referrals">
      <Box
        sx={{
          paddingBottom: "30px",
        }}
      >
        <Image
          src={ReferralIcon}
          sx={{
            width: "70px",
          }}
        ></Image>
        <Text
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            marginTop: "12px",
          }}
        >
          PEPES Spin{" "}
        </Text>
        <Text
          sx={{
            marginTop: "20px",
          }}
        >
          Invite a friend to earn 20% of the points they make. And if your
          friend refers another friend, you'll earn extra 5% of their points,
          too!
        </Text>
        <Box
          sx={{
            borderRadius: "17px",
            border: "1px solid  #97E181",
            padding: "10px 15px",
            marginTop: "25px",
          }}
        >
          <Text
            sx={{
              color: "#A1A1A1",
              fontSize: "13px",
            }}
          >
            You Referral Link
          </Text>
          <Flex
            sx={{ marginTop: "10px" }}
            justify={"space-between"}
            align={"center"}
          >
            <Text
              sx={{
                width: "200px",
                fontSize: "13px",
              }}
            >
              https://yologames.io/yolo/blast?code=4896C
            </Text>
            <Image
              src={CopyIcon}
              sx={{ width: "34px" }}
              onClick={() => {
                onCopy();
              }}
            ></Image>
          </Flex>
        </Box>
      </Box>
    </PlayDrawer>
  );
};

export default ReferralDrawer;
