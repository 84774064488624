import { api } from ".";

export const getGameHistory = async (pageIndex: number, status: string) => {
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(`/RingGame/History/${pageIndex}/${status}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getGameIngo = async (roundId: string) => {
  const url = roundId
    ? `/RingGame/RoundHistory/${roundId}`
    : `/RingGame/RoundHistory/`;
  const token = sessionStorage.getItem("jwtToken");
  const response = await api.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
