import "swiper/css/bundle";
import { Box, useMediaQuery, Image, Flex } from "@chakra-ui/react";
import { ReactElement, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useOutlet } from "react-router-dom";
import Start from "./components/Start";
import Nav from "./components/Nav";
import {
  mockTelegramEnv,
  parseInitData,
  useInitData,
  useLaunchParams,
} from "@tma.js/sdk-react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import "./AppRoutes.css";
import { TonConnectButton, TonConnectUIProvider } from "@tonconnect/ui-react";
import Header from "./components/Header";
import NotificationsSystem, {
  atalhoTheme,
  setUpNotifications,
  useNotifications,
} from "reapop";
import toast, { Toaster } from "react-hot-toast";

const search = new URLSearchParams(window.location.search);

if (search.get("outer")) {
  const initDataRaw = new URLSearchParams([
    [
      "user",
      JSON.stringify({
        id: 88888888,
        first_name: "Andrew",
        last_name: "Rogue",
        username: "rogue",
        language_code: "en",
        is_premium: true,
        allows_write_to_pm: true,
      }),
    ],
    [
      "hash",
      "89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31",
    ],
    ["auth_date", Math.floor(new Date().getTime() / 1000).toString()],
    ["start_param", "debug"],
    ["chat_type", "sender"],
    ["chat_instance", "8428209589180549439"],
  ]).toString();

  mockTelegramEnv({
    themeParams: {
      accentTextColor: "#6ab2f2",
      bgColor: "#17212b",
      buttonColor: "#5288c1",
      buttonTextColor: "#ffffff",
      destructiveTextColor: "#ec3942",
      headerBgColor: "#17212b",
      hintColor: "#708499",
      linkColor: "#6ab3f3",
      secondaryBgColor: "#232e3c",
      sectionBgColor: "#17212b",
      sectionHeaderTextColor: "#6ab3f3",
      subtitleTextColor: "#708499",
      textColor: "#f5f5f5",
    },
    initData: parseInitData(initDataRaw),
    initDataRaw,
    version: "7.2",
    platform: "tdesktop",
  });
}
const App = (): ReactElement => {
  const location = useLocation();
  const [init, setInit] = useState(false);
  const currentOutlet = useOutlet();
  const { notifications, dismissNotification } = useNotifications();

  const handleInit = () => {
    setInit(true);
  };

  useEffect(() => {
    setUpNotifications({
      defaultProps: {
        position: "top-right",
        dismissible: true,
        dismissAfter: 3000,
      },
    });
  }, []);

  return (
    // TO-DO: use color mode when implementing light/dark
    <TonConnectUIProvider manifestUrl="https://ton-connect.github.io/demo-dapp-with-wallet/tonconnect-manifest.json">
      <Box
        minH="100%"
        color="#000"
        height={"100%"}
        sx={{
          position: "relative",
          background: "#000",
          lineHeight: "1.2",
          fontFamily: "Avenir",
        }}
      >
        {/* <Box
          sx={{
            height: "calc(100% - 65px)",
          }}
        >
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={location.pathname}
              timeout={300}
              classNames="layout-main-page"
            >
              {currentOutlet}
            </CSSTransition>
          </SwitchTransition>
        </Box>
        <Nav></Nav> */}
        {init ? (
          <Box
            sx={{
              height: "calc(100% - 65px)",
            }}
          >
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={location.pathname}
                timeout={150}
                classNames="layout-main-page"
              >
                {currentOutlet}
              </CSSTransition>
            </SwitchTransition>
            <Nav></Nav>
          </Box>
        ) : (
          <Start onInitChange={handleInit}></Start>
        )}
        <NotificationsSystem
          notifications={notifications}
          // 3. Pass the function used to dismiss a notification.
          dismissNotification={(id) => dismissNotification(id)}
          // 4. Pass a builtIn theme or a custom theme.
          theme={atalhoTheme}
        ></NotificationsSystem>
        <Toaster />
      </Box>
    </TonConnectUIProvider>
  );
};

export default App;
