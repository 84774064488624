import React, { ReactElement } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import App from "./App";

import Home from "./pages/Home";
import Leaderboard from "./pages/Leaderboard";
import Friends from "./pages/Friends";
import Culture from "./pages/Culture";
import Earn from "./pages/Earn";
import Play from "./pages/Play";

const AppRoutes = (): ReactElement => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="/" element={<Home />} />
        <Route
          path="/leaderboard"
          element={<Leaderboard></Leaderboard>}
        ></Route>
        <Route path="/earn" element={<Play></Play>}></Route>
        <Route path="/friends" element={<Friends></Friends>}></Route>
        <Route path="/culture" element={<Culture></Culture>}></Route>
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
